import { NgModule } from '@angular/core';

import { LayoutComponent } from './layout.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { NavsearchComponent } from './header/navsearch/navsearch.component';
import { OffsidebarComponent } from './offsidebar/offsidebar.component';
import { UserblockComponent } from './sidebar/userblock/userblock.component';
import { UserblockService } from './sidebar/userblock/userblock.service';
import { FooterComponent } from './footer/footer.component';

import { SharedModule } from '../shared/shared.module';
import {TranslateModule} from "@ngx-translate/core";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {DropDownListModule} from "@syncfusion/ej2-angular-dropdowns";
import {DatePickerAllModule} from "@syncfusion/ej2-angular-calendars";
import {PagesModule} from "../routes/pages/pages.module";
import { SupportModalComponent } from './header/support-modal/support-modal.component';
import {MatProgressBarModule} from "@angular/material/progress-bar";
import { ModalAnotacoesComponent } from './header/anotacoes/modal-anotacoes/modal-anotacoes.component';
import {MatSelectModule} from "@angular/material/select";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import { AnotacoesComponent } from './header/anotacoes/anotacoes.component';
import {NgxMaskModule} from "ngx-mask";
import {MatMenuModule} from "@angular/material/menu";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {MatChipsModule} from "@angular/material/chips";
import {MatExpansionModule} from "@angular/material/expansion";
import { VisualizarArquivosComponent } from './header/anotacoes/modal-anotacoes/visualizar-arquivos/visualizar-arquivos.component';
import {PipesModule} from "../../util/pipes/pipes.module";

@NgModule({
  imports: [
    SharedModule,
    TranslateModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    // RouterModule.forRoot(),
    MatIconModule,
    MatDatepickerModule,
    DropDownListModule,
    DatePickerAllModule,
    PagesModule,
    MatProgressBarModule,
    MatSelectModule,
    MatSlideToggleModule,
    NgxMaskModule,
    MatMenuModule,
    DragDropModule,
    MatChipsModule,
    MatExpansionModule,
    PipesModule
  ],
    providers: [
        UserblockService
    ],
    declarations: [
        LayoutComponent,
        SidebarComponent,
        UserblockComponent,
        HeaderComponent,
        NavsearchComponent,
        OffsidebarComponent,
        FooterComponent,
        SupportModalComponent,
        ModalAnotacoesComponent,
        AnotacoesComponent,
        VisualizarArquivosComponent,
    ],
    exports: [
        LayoutComponent,
        SidebarComponent,
        UserblockComponent,
        HeaderComponent,
        NavsearchComponent,
        OffsidebarComponent,
        FooterComponent
    ]
})
export class LayoutModule { }
