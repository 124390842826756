import {Component, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import * as XLSX from 'xlsx';
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-visualizar-arquivos',
  templateUrl: './visualizar-arquivos.component.html',
  styleUrls: ['./visualizar-arquivos.component.scss']
})
export class VisualizarArquivosComponent implements OnInit {

  public name: any = '';
  public url: any;
  public fileType: any;
  public enviando: any = false;
  public spreadsheetData: any;

  constructor(public activeModal: NgbActiveModal,
              public modalService: NgbModal) {
  }

  ngOnInit() {
    if (this.isSheet()) this.loadXlsxFile()
    if (this.isCsv()) this.loadCsvFile()
  }

  save() {
    // const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = this.url;
    a.download = this.name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(this.url);
  }

  isDoc() {
    return this.fileType === 'doc' || this.fileType === 'docx' || this.fileType === 'txt' || this.fileType === 'pdf';
  }

  isSheet() {
    return this.fileType === 'xls' || this.fileType === 'xlsx';
  }

  isCsv() {
    return this.fileType === 'csv';
  }

  isImage() {
    return this.fileType === 'jpg' || this.fileType === 'jpeg' || this.fileType === 'png';
  }

  loadXlsxFile() {
    fetch(this.url)
      .then(response => response.arrayBuffer())
      .then(data => {
        const workbook = XLSX.read(new Uint8Array(data), {type: 'array'});
        const sheet = workbook.Sheets[workbook.SheetNames[0]];
        this.spreadsheetData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        this.generateTableFromJson(this.spreadsheetData);
      })
      .catch(error => {
        console.error('Error loading XLSX file:', error);
      });
  }

  loadCsvFile() {
    fetch(this.url)
      .then(response => response.text())
      .then(data => {
        const workbook = XLSX.read(data, { type: 'string' });
        const sheet = workbook.Sheets[workbook.SheetNames[0]];
        this.spreadsheetData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        this.generateTableFromJson(this.spreadsheetData);
      })
      .catch(error => {
        console.error('Error loading CSV file:', error);
      });
  }

  generateTableFromJson(jsonData: any[]) {
    let table = '<table border="1">';
    jsonData.forEach((row, rowIndex) => {
      table += '<tr>';
      row.forEach((cell) => {
        table += rowIndex === 0 ? `<th>${cell}</th>` : `<td>${cell}</td>`;
      });
      table += '</tr>';
    });
    table += '</table>';
    document.getElementById('table').innerHTML = table;
  }
}
