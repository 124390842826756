import { NgModule } from '@angular/core';
import {DatePipe, TimePipe} from "./DateTimePipe";
import {RemoveEspecialCaracters} from "./removeEspecialCaracters";
import {ValueFormat} from "./valueFormat";
import {SafePipe} from "./SafePipe";

@NgModule({
  imports: [

  ],
  declarations: [
    DatePipe,
    RemoveEspecialCaracters,
    SafePipe,
    ValueFormat,
    TimePipe
  ],
  exports: [
    DatePipe,
    RemoveEspecialCaracters,
    SafePipe,
    ValueFormat,
    TimePipe
  ]
})
export class PipesModule { }


