import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Snackbar} from "../../../../../util/snackbar";
import {ErrorUtil} from "../../../../../util/error";
import {HttpErrorResponse} from "@angular/common/http";
import {CompanyNotesProvider} from "../../../../../providers/company/company/company-notes";
import * as moment from 'moment';
import {
  AdicionarArquivosComponent
} from "../../../../routes/cadastros/enviar-arquivos/adicionar-arquivos/adicionar-arquivos.component";
import {ReceiptProvider} from "../../../../../providers/receipt/receipt";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {Observable} from "rxjs";
import {map, startWith} from "rxjs/operators";
import {UserProvider} from "../../../../../providers/user/user";
import {VisualizarArquivosComponent} from "./visualizar-arquivos/visualizar-arquivos.component";

@Component({
  selector: 'app-modal-anotacoes',
  templateUrl: './modal-anotacoes.component.html',
  styleUrls: ['./modal-anotacoes.component.scss']
})
export class ModalAnotacoesComponent implements OnInit {
  @ViewChild('receiptInput', {static: false}) receiptInput: ElementRef<HTMLInputElement>;

  public visualizar: boolean = false;
  public editar: boolean = false;
  public enviado: boolean = false;
  public enviando: boolean = false;
  public form: FormGroup;
  public existente;

  public allReceipts: any[];
  public allImages: any[] = [];
  public filteredReceipts: any[];
  public receiptCtrl = new FormControl();
  public receipts: any = [];
  public selectable = true;
  public removable = true;
  public filteredReceipt: Observable<any[]>;
  public separatorKeysCodes: number[] = [ENTER, COMMA];

  public allUsers: any[];
  public carregandoImagens: boolean = false;
  public erroImage: boolean = false;

  constructor(public activeModal: NgbActiveModal,
              public fb: FormBuilder,
              public provider: CompanyNotesProvider,
              public modalService: NgbModal,
              public snackBar: Snackbar,
              public receiptProvider: ReceiptProvider,
              public userProvider: UserProvider,
              public erroUtil: ErrorUtil) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      title: ['', Validators.required],
      description: [''],
      notification: [false],
      date: [''],
      mail: [false],
      users: [[]]
    });

    this.userProvider.getAllCollaborators().then((users: any[]) => {
      this.allUsers = users;
      this.receiptProvider.getReceiptsByCompanyId().then((receipts: any[]) => {
        this.allReceipts = receipts.map(receipt => ({
          ...receipt,
          formatedDate: moment(receipt.date).locale('pt-BR').format('DD/MM/YYYY')
        }));
        this.filteredReceipts = this.allReceipts;
        this.initializeSearchSelect();

        if (this.existente) {
          this.form.patchValue({
            title: this.existente.title,
            description: this.existente.description,
            date: this.existente.dueDate ? moment(this.existente.dueDate).format('YYYY-MM-DD') : ''
          });
          this.form.get('notification').setValue(!!this.existente.dueDate);
          if (this.existente.fileIds) {
            this.receipts = this.allReceipts.filter(receipt => this.existente.fileIds.includes(receipt.id));
            if (this.visualizar) {
              this.allImages = this.receipts.filter(receipt => receipt.fileType === 'png' || receipt.fileType === 'jpg' || receipt.fileType === 'jpeg');
              if (this.allImages.length > 0) this.showImages();
            }
          }
          this.form.get('mail').setValue(!!this.existente.userIdsForNotification);
          if (this.existente.userIdsForNotification) {
            this.form.get('users').setValue(this.allUsers.filter(user => this.existente.userIdsForNotification.includes(user.id)));
          }
        }
      });
    });
  }

  initializeSearchSelect() {
    this.filteredReceipt = this.receiptCtrl.valueChanges.pipe(
      startWith(''),
      map((fruit: string | null) => fruit ? this._filterR(fruit) : this.allReceipts.slice()));
  }

  private _filterR(value: any): any[] {
    if (typeof value === 'string') {
      const name = value;
      return value ? this.allReceipts.filter(fruit => fruit.description.toLowerCase().includes(name.toLowerCase())) : this.allReceipts;
    }
  }

  newOrEdit() {
    this.enviado = true;
    this.enviando = true;
    if (this.checkForm()) {
      let obj: any = {
        ...(this.editar && {id: this.existente.id}),
        title: this.form.get('title').value,
        description: this.form.get('description').value || null,
        createdAt: moment().format('YYYY-MM-DDTHH:mm:ss'),
        dueDate: this.form.get('notification').value ? moment(this.form.get('date').value).format('YYYY-MM-DDTHH:mm:ss') : null,
        fileIds: this.receipts.length ? this.receipts.map(receipt => receipt.id) : [],
        userIdsForNotification: this.form.get('notification').value && this.form.get('users').value.length ? this.form.get('users').value.map(user => user.id) : []
      };
      const request = this.editar ? this.provider.put(obj) : this.provider.post(obj);
      request.then((resp) => {
        this.snackBar.openLong(`Sucesso! Anotação ${this.editar ? 'editada' : 'inserida'} com sucesso!`, 'success');
        this.activeModal.close(resp);
      }).catch((result: HttpErrorResponse) => {
        console.log(result);
        this.enviando = false;
        this.snackBar.openLong(`Não foi possível ${this.editar ? 'editar' : 'inserir'}! ` + this.erroUtil.checkErrorStatus(result, result.status, result.error, this.editar ? 'folder' : 'grouper'), 'erro');
      });
    } else {
      this.enviando = false;
      this.snackBar.openLong("Há erros no formulário. Confira antes de tentar enviar novamente!", 'erro');
    }
  }

  checkForm(): boolean {
    let obj = this.form.getRawValue();
    if (!obj.title && obj.title.length <= 0) {
      this.snackBar.openLong("O título não pode estar vazio!", 'erro');
      return false;
    } else if (this.form.get('notification').value == true && (!obj.date)) {
      this.snackBar.openLong("A data não pode ser vazia!", 'erro');
      return false;
    } else if (this.form.get('mail').value == true && (!this.form.get('users').value)) {
      this.snackBar.openLong("Selecione pelo menos um usuário!", 'erro');
      return false;
    }
    return true;
  }

  formatDate(date: any) {
    return moment(date).format('DD/MM/YYYY');
  }

  isToday(date: any) {
    return moment(date).isSame(moment(), 'day');
  }

  pastToday(date: any) {
    return moment(date).isBefore(moment(), 'day');
  }

  openFileModal() {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark' ? 'dark-theme' : ''
    };
    try {
      const modalRef = this.modalService.open(AdicionarArquivosComponent, modalOptions);
      modalRef.result.then((result: any) => {
        if (result !== 'Cancelou') {
          this.receiptProvider.getReceiptsByCompanyId().then((receipts: any[]) => {
            this.allReceipts = receipts.map(receipt => ({
              ...receipt,
              formatedDate: moment(receipt.date).locale('pt-BR').format('DD/MM/YYYY')
            }));
            this.filteredReceipts = this.allReceipts;
            this.receiptCtrl.setValue('');
            if (result.length) {
              this.receipts.push(...result);
            }
          });
        }
      });
    } catch (error) {
      console.error('', error);
    }
  }

  removeReceipt(indx): void {
    this.receipts.splice(indx, 1);
  }

  showImages() {
    this.carregandoImagens = true;
    this.erroImage = false;
    const mimeTypes = {
      pdf: 'application/pdf',
      xls: 'application/vnd.ms-excel',
      xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      doc: 'application/msword',
      docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      txt: 'text/plain',
      png: 'image/png',
      jpg: 'image/jpeg',
      jpeg: 'image/jpeg'
    };

    this.allImages.forEach((image, i) => {
      this.receiptProvider.downloadFile(image.file).then((value: any) => {
        const type = mimeTypes[image.fileType];
        const blob = new Blob([value], {type});
        const url = window.URL.createObjectURL(blob);

        let div = document.createElement('div');
        div.classList.add('col-6');
        div.style.marginBottom = '10px';

        const img = document.createElement('img');
        img.src = url;
        img.style.width = '100%';
        img.style.height = 'auto';
        div.appendChild(img);

        const button = document.createElement('button');
        button.classList.add('btn', 'btn-info');
        button.style.position = 'absolute';
        button.style.right = '20px';
        button.style.bottom = '5px';
        button.innerHTML = '<i class="fas fa-external-link-square-alt"></i>';
        button.onclick = () => this.openFile(url, image.fileType, image.description);
        div.appendChild(button);

        document.getElementById('imagens').appendChild(div);
        if (i === this.allImages.length - 1) this.carregandoImagens = false;
      }).catch((error) => {
        this.carregandoImagens = false;
        this.erroImage = true;
        console.log(error);
        this.snackBar.openLong('Erro ao carregar imagem', 'error');
      });
    });
  }

  downloadFile(receipt) {
    this.enviando = true;
    const mimeTypes = {
      pdf: 'application/pdf',
      xls: 'application/vnd.ms-excel',
      xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      doc: 'application/msword',
      docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      txt: 'text/plain',
    };
    this.receiptProvider.downloadFile(receipt.file).then((value: any) => {
      const type = mimeTypes[receipt.fileType];
      const blob = new Blob([value], {type});
      const url = window.URL.createObjectURL(blob);
      this.openFile(url, receipt.fileType, receipt.description);
      this.enviando = false;
    }).catch((error) => {
      this.snackBar.openLong('Erro ao baixar arquivo', 'error');
    });
  }

  openFile(url, type, name) {
    let fileTypes = ['pdf', 'xls', 'xlsx', 'csv', 'txt', 'png', 'jpg', 'jpeg'];
    if (!fileTypes.includes(type)) {
      this.snackBar.open('O arquivo está sendo baixado!', 'atencao');
      const a = document.createElement('a');
      a.href = url;
      a.download = name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      return;
    }
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark' ? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(VisualizarArquivosComponent, modalOptions);
    modalRef.componentInstance.url = url;
    modalRef.componentInstance.fileType = type;
    modalRef.componentInstance.name = name;
  }

  selectedReceipt(event: MatAutocompleteSelectedEvent) {
    if (event.option.value != 0) {
      const eventInsert = event.option ? event.option.value : event;
      if (!this.receipts.some(receipt => receipt.id === eventInsert.id)) {
        this.receipts.push(eventInsert);
        this.receiptInput.nativeElement.value = '';
        this.receiptCtrl.setValue(null);
      } else {
        this.snackBar.open('Arquivo já selecionado!', 'atencao');
      }
    }
  }

  limpaUsers(bool) {
    if (bool) {
      this.form.get('users').setValue(this.allUsers);
    } else {
      this.form.get('users').setValue([])
    }
  }

  otherExtensions(fileType: any) {
    return fileType !== 'pdf' && fileType !== 'xls' && fileType !== 'xlsx' && fileType !== 'doc' && fileType !== 'docx'
      && fileType !== 'txt' && fileType !== 'csv' && fileType !== 'png' && fileType !== 'jpg' && fileType !== 'jpeg';
  }
}
