import { Component, OnInit } from '@angular/core';
import {Maintenance} from "../../../../model/dto/status/Maintenance";
import { Incident } from 'src/model/dto/status/Incident';
import {MaintenanceProvider} from "../../../../providers/status/maintenanceProvider";
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import {CheckPermission} from "../../../../service/checkPermission";

@Component({
  selector: 'app-service-status',
  templateUrl: './service-status.component.html',
  styleUrls: ['./service-status.component.scss']
})
export class ServiceStatusComponent implements OnInit {

  maintenance: Maintenance[] = [];
  newsObj = null;

  constructor(private checkPermission: CheckPermission,
              private maintenanceProvider: MaintenanceProvider,
              private domSanitizer: DomSanitizer) { }

  ngOnInit() {
    // this.getOverallData();
    this.getStatus();
    this.getIncidents();
  }

  getOverallData(){
    this.maintenanceProvider.getOverallData().then((value: any) => {
    });
  }

  getIncidents(){
    this.maintenanceProvider.getAllIncident().then((value: Incident[]) => {
      let incident = value.filter(x => x.name.includes("[NEWS_FARMCONT]"));
      if(incident.length == 0) return;
      this.newsObj = {
        title: incident[0].name.split("[NEWS_FARMCONT]")[1].replace(/_/g, " "),
        description: incident[0].updates[0].message.split("§")[0],
        link: incident[0].updates[0].message.split("§")[1]
      }
      this.newsObj.link != undefined ? this.newsObj.link = this.domSanitizer.bypassSecurityTrustUrl(this.newsObj.link) : this.newsObj.link = null;
    });
  }

  getStatus() {
    this.maintenance = [];
    this.maintenanceProvider.getAllMaintenance().then((value: Maintenance[]) => {
      let user_roles = this.checkPermission.getRoles();
      value.forEach(x => {
        if (x.name.includes("[MAINTENANCE_FARMCONT]")) {
          x.name = x.name.split("[MAINTENANCE_FARMCONT]")[1].replace(/_/g, " ");
          let maintenance_roles = x.components.filter(y => y.name.includes("ROLE_")).map(y => y.name);
          x.components = x.components.filter(y => !y.name.includes("ROLE_"));
          if (!maintenance_roles.length || maintenance_roles.some(r => user_roles.includes(r))) {
            this.maintenance.push(x);
          }
        }
      });
    });
  }
}
