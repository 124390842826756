import {Injectable} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {CheckPermission} from 'src/service/checkPermission';

@Injectable()
export class UserConfig {

  public userPreferences: any = [];

  constructor(
    public fb: FormBuilder,
    public checkPermission: CheckPermission
  ) {
  }


  /**
   * Constroi um objeto com as preferências do usuário
   *
   * @param   userData    user data não tratada
   * @returns objeto com as preferências do usuário
   * */
  initializeUserConfig(userData) {
    //console.log("initializeUserConfig ~ userData:", userData)
    let userConfig: any = {};
    let parsedUserData = this.parseUserData(userData == null ? [] : userData);
    //console.log("initializeUserConfig ~ parsedUserData:", parsedUserData)
    userConfig.emailConfig = this.userConfigEmail(parsedUserData);
    //console.log("initializeUserConfig ~ userConfig.emailConfig:", userConfig.emailConfig)
    return userConfig;
  }

  /**
   * Trata a userData para JSON
   *
   * @param   userData    userData c o campo value como string
   * @returns userData tratada, dando parse no campo value
   * */
  parseUserData(userData) {
    let parsedUserData = [];
    userData.forEach((element) => {
      parsedUserData.push({
        cType: element.cType,
        value: JSON.parse(element.value)
      })
    })
    return parsedUserData;
  }

  /**
   * Cria formgroups com as opções de notificações via email utilziando as preferências do usuário
   *
   * @param   userData    user data já tratada - preferências do usuário
   * @returns emailFormGroups de formGroups c as opções de notificações via email
   * */
  userConfigEmail(userData) {
    let defaultEmailConfig = this.defaultEmailConfig();
    //console.log("userConfigEmail ~ defaultEmailConfig:", defaultEmailConfig)
    let emailFormGroups = [];
    defaultEmailConfig.forEach((element) => {
      let userDataElement = userData.find(userDataElement => userDataElement.cType === element.cType);
      //console.log("defaultEmailConfig.forEach ~ userDataElement:", userDataElement)
      emailFormGroups.push(
        this.fb.group({
          cType: [element.cType],
          category: [element.value.category],
          description: [element.value.description],
          value: [userDataElement === undefined ? element.value.value : userDataElement.value.value, Validators.required],
        })
      )
    })
    return emailFormGroups;
  }

  /**
   * Cria um array com as opções de notificação via email baseado na role do user
   *
   * @returns array de opções de notificação
   * */
  defaultEmailConfig() {
    let defaultData = [];
    defaultData = defaultData.concat(this.defaultEmailDataGeneral());
    this.checkPermission.isAccountant() ? defaultData = defaultData.concat(this.defaultEmailDataAccountant()) : '';
    this.checkPermission.isAccountantAdmin ? defaultData = defaultData.concat(this.defaultEmailDataAccountantAdmin()) : '';
    defaultData = defaultData = this.uniqueArray(defaultData);
    return defaultData;
  }

  /**
   * Cria um array de opções de notificação comuns a todas as roles
   *
   * @returns array de opções de notificação
   */
  defaultEmailDataGeneral() {
    return [
      {
        cType: 'NEW_LOGIN',
        value: {
          category: 'EMAIL',
          description: 'Efetuação de login',
          value: true
        }
      },
      {
        cType: 'NEW_LOGIN_TRY',
        value: {
          category: 'EMAIL',
          description: 'Tentativa de login',
          value: true
        }
      },
    ];
  }

  /**
   * Cria um array de opções de notificação das roles: ACCOUNTANT_ADMIN e ACCOUNTANT_COLLABORATOR
   *
   * @returns array de opções de notificação
   * */
  defaultEmailDataAccountant() {
    return [
      {
        cType: 'DELETE_COMPANY',
        value: {
          category: 'EMAIL',
          description: 'Remoção de empresa',
          value: true
        }
      },
      {
        cType: 'ACCOUNT_BATCH_CHANGE',
        value: {
          category: 'EMAIL',
          description: 'Alteração em lotes de conta movimento',
          value: true
        }
      },
      {
        cType: 'PROPERTY_BATCH_CHANGE',
        value: {
          category: 'EMAIL',
          description: 'Alteração em lotes de imóvel',
          value: true
        }
      },
      {
        cType: 'ACCOUNTPLAN_BATCH_CHANGE',
        value: {
          category: 'EMAIL',
          description: 'Alteração em lotes de plano de conta',
          value: true
        }
      },
      {
        cType: 'PARTICIPANT_BATCH_CHANGE',
        value: {
          category: 'EMAIL',
          description: 'Alteração em lotes de participante',
          value: true
        }
      },
      {
        cType: 'DATE_BATCH_CHANGE',
        value: {
          category: 'EMAIL',
          description: 'Alteração em lotes de data',
          value: true
        }
      },
      {
        cType: 'CERTIFICATE_EXPIRATION',
        value: {
          category: 'EMAIL',
          description: 'Certificados a expirar em 30 dias',
          value: true
        }
      },
      {
        cType: 'NOTE_EXPIRATION',
        value: {
          category: 'EMAIL',
          description: 'Anotações a expirar hoje',
          value: true
        }
      }
    ]
  }

  /**
   * Cria um array de opções de notificação da role: ACCOUNTANT_ADMIN
   *
   * @returns array de opções de notificação
   * */
  defaultEmailDataAccountantAdmin() {
    return [
      // {
      //     cType: 'DELETE_COMPANY',
      //     value: {
      //         category: 'EMAIL',
      //         description: 'Emissão de notas no Emissor',
      //         value: true
      //     }
      // },
    ]
  }

  /**
   * Filtra o array de opções de notificações para remoção de duplicatas (baseado no campo cType)
   *
   * @param   array   array a ser filtrado
   * @returns array com elementos únicos
   * */
  uniqueArray(array) {
    let uniqueArray = [];
    array.forEach(element => {
      if (uniqueArray.find(uniqueElement => uniqueElement.cType === element.cType) === undefined) {
        uniqueArray.push(element);
      }
    });
    return uniqueArray;
  }

}
